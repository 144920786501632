import PropTypes from 'prop-types';
import React, { useCallback, useState } from "react";

import { Typography, Box, Button } from "@material-ui/core";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import currency from "currency.js";
import { times } from "lodash";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import no_img from "../images/no-image.png";
import ModalBuyProduct from "../ModalBuyProduct/ModalBuyProduct";

CatalogSectionProduct.propTypes = {
  data: PropTypes.object,
  view: PropTypes.string,
};

CatalogSectionProduct.defaultProps = {
  view: "table",
  data: {}
}

export default function CatalogSectionProduct({ data, view }) {
  const style = {
    wrapperProduct: {
      padding: {
        xs: '9px',
        sm: '16px',
        lg: '16px',
      },
      border: '1px solid #E7E8EA',
      borderRadius: {
        xs: '8px',
        sm: '12px',
        lg: '16px',
      },
      display: 'flex',
      width: '100%',
      transition: 'box-shadow 0.20s',

      '&:hover': {
        boxShadow: '0px 10px 32px rgba(47, 45, 40, 0.15)',
        transition: 'box-shadow 0.20s',
        border: '1px solid transparent',

        '& .slick-dots li': {
          overflow: 'visible',
          opacity: 1,
        }
      },
    },
    wrapperImg: {
      width: {
        xs: '76px',
        sm: '128px',
        lg: view === 'table' ? '197px' : '170px',
      },
      overflow: 'hidden',
      marginRight: '10px',
      position: 'relative',

      '& .slick-track img': {
        borderRadius: '4px',
      },
      '& .slick-dots': {
        display: 'flex !important',
        position: 'relative',
        bottom: '16px',
        justifyContent: 'space-between',
      },
      '& .slick-dots li': {
        overflow: 'hidden',
        opacity: 0,
        width: '100%',
        background: '#BEBEBE',
        height: '5px',
        borderRadius: '10px',
        transition: 'opacity 0.2s',

        '&.slick-active': {
          background: '#FF780F',
        },
        '& > div': {
          height: '100%',
        }
      },
      '& .slick-slide': {
        height: '130px',
        minHeight: '100% !important',

        '& > div': {
          height: '100% !important',
          minHeight: '100% !important',
          width: '100%',
          minWidth: '100%',

          '& > div': {
            overflow: 'hidden',
            width: '100%',
            height: '100%',
            display: 'flex !important',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '4px',

            '& img': {
              height: '100% !important',
              width: 'auto !important',
              maxWidth: 'unset !important',
            }
          }
        }
      }
    },
    kit: {
      position: 'absolute',
      zIndex: 2,
      top: {
        xs: 'calc(100% - 18px)',
        sm: 'calc(100% - 18px)',
        lg: '0'
      },
      right: 0,
      background: '#21A2FF',
      color: '#ffffff',
      fontSize: {
        xs: '10px',
        sm: '12px',
        lg: '12px'
      },
      fontWeight: 500,
      borderRadius: '2px',
      padding: '0 7px',
      width: {
        xs: '100%',
        sm: '100%',
        lg: 'auto'
      },
      textAlign: {
        xs: 'center',
        sm: 'center',
        lg: 'auto'
      },
    },
    floatBlocks: {
      position: 'absolute',
      zIndex: 2,
      left: 0,
      top: {
        xs: '0',
        sm: '0',
        lg: '0'
      },
    },
    sale: {
      background: '#67BF3D',
      color: '#ffffff',
      fontWeight: 700,
      padding: '0 7px',
      borderRadius: '2px',
      display: 'inline-block',
      width: 'auto',
      fontSize: {
        xs: '10px',
        sm: '12px',
        lg: '12px'
      },
    },
    reserved: {
      background: '#f80024',
      color: '#ffffff',
      fontWeight: 700,
      padding: '0 7px',
      borderRadius: '2px',
      display: 'inline-block',
      fontSize: {
        xs: '10px',
        sm: '12px',
        lg: '12px'
      },
    },
    blockContent: {
      width: {
        xs: 'calc(100% - 86px)',
        sm: 'calc(100% - 110px)',
        lg: 'calc(100% - 140px)',
      },
    },
    title: {
      fontSize: {
        xs: '10px',
        sm: '18px',
        lg: '20px'
      },
      color: '#0D1D32',
      fontWeight: 500,
      marginBottom: {
        xs: '4px',
      },
      overflow: {
        xs: 'visible',
        sm: 'visible',
        lg: 'hidden',
      },
      textOverflow: 'ellipsis',
      whiteSpace: {
        xs: 'normal',
        sm: 'normal',
        lg: 'nowrap',
      },
    },
    location: {
      fontSize: view === 'table' ? '13px' : '12px',
      marginTop: view === 'table' ? '4px' : '0px',
      color: '#0D1D32',
      fontWeight: 400,
      display: {
        xs: 'none',
        sm: 'none',
        lg: 'block'
      },

      '& span': {
        fontWeight: 500,
      }
    },
    blockPrice: {
      display: 'flex',
      justifyContent: {
        xs: 'space-between',
        sm: 'flex-end',
        lg: 'flex-end'
      },
      alignItems: {
        xs: 'flex-end',
        sm: 'unset',
        lg: 'center'
      },
      marginTop: {
        sm: '15px',
        lg: '0'
      },
    },
    wrapperCondition: {
      marginTop: {
        xs: '0',
        sm: '6px',
        lg: view === 'table' ? '6px' : '12px'
      },
      marginBottom: {
        xs: 0,
        sm: '6px',
        lg: '6px',
      },
      display: 'flex',
      alignItems: 'center'
    },
    condition: {
      fontSize: {
        xs: '10px',
        sm: '12px',
        lg: '14px',
      },
      color: '#0D1D32',
      fontWeight: 400,
    },
    countsQuality: {
      marginLeft: '6px',
      display: {
        xs: 'none',
        sm: 'flex',
        lg: 'flex',
      },
      gap: '2px',
    },
    countQuality: {
      width: '8px',
      height: '8px',
      borderRadius: '2px',
      background: '#CFD2D6',
    },
    wrapperPriceOld: {
      display: {
        xs: 'none',
        sm: 'block',
        lg: 'block'
      },
    },
    priceOld: {
      color: '#6E7884',
      fontSize: {
        xs: '12px',
        sm: '16px',
        lg: '16px'
      },
      fontWeight: 500,
      position: 'relative',
      width: 'auto',
      marginRight: '20px',
      display: {
        xs: 'none',
        sm: 'inline-block',
        lg: 'inline-block'
      },

      '&:before': {
        content: "''",
        display: 'inline-block',
        width: 'calc(100% + 10px)',
        height: '3px',
        background: 'rgb(255, 120, 15)',
        position: 'absolute',
        left: '-4px',
        bottom: '11px',
        opacity: '0.6',
        borderRadius: '8px',
        transform: 'rotate(350deg)',
      },
    },
    priceOldMob: {
      display: {
        xs: 'inline-block',
        sm: 'none',
        lg: 'none'
      },
    },
    price: {
      fontSize: {
        xs: '14px',
        sm: '20px',
        lg: '20px'
      },
      color: '#2F2F2F',
      fontWeight: 700,
      lineHeight: '1.1',
      width: '100%',
    },
    nds: {
      color: '#868E98',
      fontSize: {
        xs: '10px',
        sm: '12px',
        lg: '12px'
      },
    },
    buy: {
      width: {
        xs: '72px',
        sm: '96px',
        lg: '96px'
      },
      height: {
        xs: '28px',
        sm: '38px',
        lg: '38px',
      },
      background: '#FF780F',
      fontSize: {
        xs: '12px',
        sm: '14px',
        lg: '14px',
      },
      fontWeight: 500,
      color: '#ffffff',
      borderRadius: '8px',
      textTransform: 'capitalize',
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',

      '&:hover': {
        background: '#ec6608',
        boxShadow: '0px 2px 4px -1px rgb(170 170 170 / 20%), 0px 4px 5px 0px rgb(170 170 170 / 14%), 0px 1px 10px 0px rgb(170 170 170 / 12%)',
      },
    },
    wrapperProductBlock: {
      width: { sm: '100%', lg: 'calc(100% / 3 - 11px)' },
      border: '1px solid #E7E8EA',
      borderRadius: '16px',
      padding: '20px',

      '&:hover': {
        boxShadow: '0px 10px 32px rgba(47, 45, 40, 0.15)',
        border: '1px solid transparent',

        '& .slick-dots li': {
          overflow: 'visible',
          opacity: 1,
        }
      }
    },
    imgBlock: {
      width: '100%',
      height: 'auto',
      minHeight: '210px',
      padding: '0',

      '& .slick-slide': {
        height: '250px',
        minHeight: '100% !important',

        '& > div': {
          height: '100% !important',
          minHeight: '100% !important',
          width: '100%',
          minWidth: '100%',

          '& > div': {
            overflow: 'hidden',
            width: '100%',
            height: '100%',
            display: 'flex !important',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '4px',

            '& img': {
              height: '100% !important',
              width: 'auto !important',
              maxWidth: 'unset !important',
            }
          }
        }
      }
    },
    titleBlock: {
      fontSize: '18px',
      fontWeight: 600,
      marginBottom: '12px',
      maxHeight: '56px',
      minHeight: '56px',
      overflow: 'hidden',
    },
    locationBlock: {
      marginBottom: '14px'
    },
  }
  const collections = data.collection || [];

  const [showModal, setShowModal] = useState(false);
  const [buyID, setBuyID] = useState(null)

  const bayProduct = (id, isOpen) => {
    if (typeof window.ym !== 'undefined') {
      window.ym(30430687, 'reachGoal', 'renew_button')
    } else {
      console.log('Метрика не инициализирована, цель - renew_send');
    }

    setShowModal(isOpen);
    setBuyID(id)
  }

  const SampleNextArrow = props => {
    // eslint-disable-next-line react/prop-types
    const { onClick } = props;
    return (
      <div
        onClick={onClick}
        style={{
          position: 'absolute',
          top: '50%',
          transform: 'translate(0px, -50%)',
          height: '100%',
          right: 0,
          display: 'flex',
          alignItems: 'center',
          width: '30px',
          zIndex: 3,
          justifyContent: 'flex-end',
        }}
      >
        <KeyboardArrowRightIcon sx={{ color: '#CFD2D6' }} />
      </div>
    );
  }

  const SamplePrevArrow = props => {
    // eslint-disable-next-line react/prop-types
    const { onClick } = props;
    return (
      <div
        onClick={onClick}
        style={{
          position: 'absolute',
          top: '50%',
          transform: 'translate(0px, -50%)',
          height: '100%',
          left: 0,
          display: 'flex',
          alignItems: 'center',
          width: '30px',
          zIndex: 3,
        }}
      >
        <KeyboardArrowLeftIcon sx={{ color: '#CFD2D6' }} />
      </div>
    );
  }

  const settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // eslint-disable-next-line react/display-name
    customPaging: () => {
      return (
        <Box></Box>
      );
    },
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          dots: false,
          nextArrow: <SampleNextArrow />,
          prevArrow: <SamplePrevArrow />,
        }
      },
    ]
  };

  const renderQuality = (type) => {
    let text = '',
      color = '',
      count = 0;

    switch (type) {
      case 1:
      case 2:
        text = 'Плохое';
        color = '#FF5550';
        count = 2;

        break;

      case 'satisfactory':
        text = 'Удовлетворительное!';
        color = '#D4B200';
        count = 3;

        break;

      case 'good':
        text = 'Хорошее!';
        color = '#35CFD9';
        count = 4;

        break;

      case 'perfect':
        text = 'Отличное!';
        color = '#67BF3D';
        count = 5;

        break;

      case 'newest':
        text = 'Новое 🔥';
        color = '#67BF3D';
        count = 'none';

        break;

      default:
        text = 'Не указано';
        color = '#2F2F2F';
        count = 0;
    }

    return (
      <Box sx={style.wrapperCondition}>
        <Typography sx={{ ...style.condition }}>
          Состояние - <span style={{ color: color, fontWeight: 500 }}>{text}</span>
        </Typography>
        {count !== 'none' && (
          <Box sx={style.countsQuality}>
            {times(count, id => {
              return (
                <Box key={`count${id}`} sx={{ ...style.countQuality, background: color }}></Box>
              )
            })}

            {times(5 - count, id => {
              return (
                <Box key={`count${id}`} sx={{ ...style.countQuality }}></Box>
              )
            })}
          </Box>
        )}
      </Box>
    )
  }

  const renderSlider = (images) => {
    return (
      <Slider {...settings}>
        {images.map((element, index) => (
          <Box key={`slide${index}`}>
            <Box alt={element.filename} component="img" src={element.url} />
          </Box>
        ))}
      </Slider>
    )
  }

  const formatMoney = useCallback((value) => {
    return currency(value, { precision: 0 }).format({
      separator: ' ',
      symbol: '¤',
      pattern: '# !',
    });
  }, []);

  if (!collections.length) {
    return (
      <Typography sx={{ fontSize: '18px', fontWeight: 400, }}>Данный раздел пуст</Typography>
    )
  }

  return (
    <>
      {view === "table" ? (
        collections.map((elem, index) => {
          return (
            <Box key={`productTable${elem.id}`} sx={style.wrapperProduct}>
              <a href={`/product/${elem.id}`}>
                <Box sx={{ ...style.wrapperImg, width: "130px" }}>
                  <Box sx={style.floatBlocks}>
                    {elem.discount > 0 && (
                        <Typography sx={style.sale}>
                          -{elem.discount}%
                        </Typography>
                    )}

                    {elem.reserved && (
                      <Typography sx={style.reserved}>
                        Зарезервирован
                      </Typography>
                    )}
                  </Box>

                  {elem.images && elem.images.length > 0 ? (
                    renderSlider(elem.images)
                  ) : (
                    <img alt={""} src={no_img} />
                  )}
                </Box>
              </a>

              <Box sx={style.blockContent}>
                {renderQuality(elem.quality)}

                <a href={`/product/${elem.id}`}>
                  <Typography sx={style.title}>{elem.title}</Typography>
                </a>

                <Typography sx={style.location}>
                  Местонахождение: <span>{elem?.address || '---'}</span>
                </Typography>

                <Box sx={style.blockPrice}>
                  <Box sx={style.wrapperPriceOld}>
                    {elem.discount > 0 && (
                      <Typography sx={style.priceOld}>{formatMoney(elem.price)}</Typography>
                    )}
                  </Box>
                  <Box sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: { xs: "row", sm: "column", lg: "column" },
                    marginRight: "11px",
                  }}>
                    {elem.discount > 0 && (
                      <Typography
                        sx={{ ...style.priceOld, ...style.priceOldMob }}>{formatMoney(elem.price)}</Typography>
                    )}
                    <Typography sx={style.price}>
                      {elem.discount > 0 ? (
                        formatMoney(elem.price - (elem.price / 100 * elem.discount))
                      ) : (
                        formatMoney(elem.price)
                      )}
                    </Typography>

                    <Typography sx={style.nds}>
                      {elem.vatRate > 0 ? (
                        "В т.ч. НДС 20%"
                      ) : (
                        "Без НДС"
                      )}
                    </Typography>
                  </Box>

                  <Button onClick={bayProduct.bind(this, elem.id, true)} sx={style.buy}>
                    Купить
                  </Button>
                </Box>
              </Box>
            </Box>
          );
        })
      ) : (
        collections.map((elem, index) => {
          return (
            <Box key={`product${elem.id}`} sx={style.wrapperProductBlock}>
              <a href={`/product/${elem.id}`}>
                <Box sx={{ ...style.wrapperImg, ...style.imgBlock }}>
                  {elem.discount > 0 && (
                    <Typography sx={style.sale}>
                      -{elem.discount}%
                    </Typography>
                  )}

                  {elem.images && elem.images.length > 0 ? (
                    renderSlider(elem.images)
                  ) : (
                    <img alt={""} src={no_img} />
                  )}
                </Box>
              </a>

              {renderQuality(elem.quality)}

              <a href={`/product/${elem.id}`}>
                <Typography sx={style.titleBlock}>
                  {elem.title.length > 44 ? elem.title.substring(0, 44) + "..." : elem.title}
                </Typography>
              </a>
              <Typography
                sx={{ ...style.location, ...style.locationBlock }}>Местонахождение: <span>{elem?.address || '---'}</span></Typography>

              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                <Box>
                  <Box sx={{ height: "24px" }}>
                    {elem.discount > 0 && (
                      <Typography sx={style.priceOld}>{formatMoney(elem.price)}</Typography>
                    )}
                  </Box>
                  <Typography sx={style.price}>
                    {elem.discount > 0 ? (
                      formatMoney(elem.price - (elem.price / 100 * elem.discount))
                    ) : (
                      formatMoney(elem.price)
                    )}
                  </Typography>
                  
                  <Typography sx={style.nds}>
                      {elem.vatRate > 0 ? (
                        "В т.ч. НДС 20%"
                      ) : "Без НДС"}
                  </Typography>
                </Box>

                <Button onClick={bayProduct.bind(this, elem.id, true)} sx={style.buy}>
                  Купить
                </Button>
              </Box>
            </Box>
          );
        })
      )}

      {showModal && <ModalBuyProduct funcHideModal={bayProduct} id={buyID} />}
    </>
  );
}